<template>
  <div class="add-contactUser">
    <LoadingOverlay v-if="isFetchingFilter" />
    <div v-else>
      <h3 class="text-primary main-title m-1 pt-1">Add User</h3>
      <ValidationObserver ref="addContactUser" v-slot="{ handleSubmit }">
        <form id="addContactUser" @submit.prevent="handleSubmit(onSubmit)">
          <CCard class="mt-2">
            <CCardBody>
              <CRow>
                <CCol lg="6">
                  <CRow name="first_name" class="mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >First Name</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="first_name"
                          :value="user.first_name"
                          :error="errors[0]"
                          @input="handleInput"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="last_name" class="mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >Last Name</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="last_name"
                          :value="user.last_name"
                          :error="errors[0]"
                          @input="handleInput"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow name="phone" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Phone</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        ref="phone"
                        :rules="{
                          required: true,
                          phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        }"
                        v-slot="{ errors }"
                      >
                        <PhoneInput
                          name="phone"
                          :value="user.phone"
                          :options="
                            options && options['dialingCode']
                              ? options['dialingCode']
                              : []
                          "
                          :error="errors[0]"
                          @change="handleInput"
                          ref="phoneNo"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow name="email" class="mb-3">
                    <label class="required col-lg-12 col-md-12">Email</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <TextInput
                          name="email"
                          :value="user.email"
                          :error="errors[0]"
                          @input="handleInput"
                          autocomplete="off"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CRow name="password" class="mb-3">
                    <label class="col-lg-12 col-md-12">Password</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider name="password" v-slot="{ errors }">
                        <PasswordInput
                          name="password"
                          :value="autoGenPassword"
                          @input="handleInput"
                          :error="errors[0]"
                          autocomplete="off"
                          :disabled="true"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <div class="text-center mx-0 mt-2 pt-2 action-area">
            <CButton
              type="submit"
              size="lg"
              color="primary"
              shape="square"
              class="px-5 m-1 mt-3"
              v-if="isMobile"
              >Submit</CButton
            >
            <CButton
              variant="outline"
              size="lg"
              type="button"
              color="primary"
              shape="square"
              class="px-5 m-1 mt-3"
              @click="resetForm"
              >Clear All</CButton
            >
            <CButton
              type="submit"
              size="lg"
              color="primary"
              shape="square"
              class="px-5 m-1 mt-3"
              v-if="!isMobile"
              >Submit</CButton
            >
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";

import { extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  confirmed,
  regex,
  min,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
  },
  data() {
    return {
      user: {},
      payload: {},
      confirmPassword: null,
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters([
      "dialingCodeWithCountryCode",
      "isFetchingFilter",
      "autoGenPassword",
    ]),
    options() {
      return {
        dialingCode: this.dialingCodeWithCountryCode || [],
      };
    },
  },
  methods: {
    ...mapActions(["initContactFetch", "showToast", "fetchAutoGenPassword"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.user, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    resetForm() {
      this.user = {};
      this.payload = {};
      this.fetchAutoGenPassword();
    },
    async onSubmit() {
      const isValid = await this.$refs.addContactUser.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.payload.password = this.autoGenPassword
        ? this.autoGenPassword
        : null;
      this.payload = {
        ...this.payload,
        contact_type_id: 10,
      };
      this.$emit("submit", this.payload);
    },
  },
  mounted() {
    this.initContactFetch();
    let isMobile = window.matchMedia(
      "only screen and (max-width: 420px)"
    ).matches;
    this.isMobile = isMobile;
  },
};
</script>
<style lang="scss" scoped>
.action-area {
  .btn {
    width: 172px;
  }
}
</style>