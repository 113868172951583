<template>
  <div class="container-md col-lg-8 col-md-8 mb-4 pb-4">
    <AddContactUser @submit="addContactUser" />
  </div>
</template>
<script>
import AddContactUser from "@/containers/ContactUser/AddContactUser";
import { mapActions } from "vuex";
export default {
  components: {
    AddContactUser,
  },
  methods: {
    ...mapActions([ "createUserNew"]),
    addContactUser(payload) {
        this.createUserNew(payload);
    },
  },
};
</script>